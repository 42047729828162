import React, { Component } from "react";

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';
import DetailCharacterContent from '../../content/DetailCharacterContent/DetailCharacterContent';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { Link } from 'react-router-dom';
import SortIcon from '@material-ui/icons/Sort';
import Grid from '@material-ui/core/Grid';


const styles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    card: {
        width: 400,
        maxWidth: '99vw',
        minHeight: 400,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column'
    },
    alignCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    textCenter: {
        textAlign: 'center'
    }
});

class WordCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            clickedChar: ''
        };
    }

    handleClickAway = () => {
        this.setState({
            anchorEl: null
        });
    };

    handleClick = (event, char) => {
        this.setState({
            anchorEl: this.state.anchorEl ? null : event.currentTarget,
            clickedChar: char        
            }
        );
    };
      
    render () {
        // Styling
        const { classes } = this.props;

        const { clickedChar } = this.state;

        const open = Boolean(this.state.anchorEl);
        const id = open ? 'simple-popper' : undefined;

        // Properties
        const { word, wordPinyin, translation, characters, showAnswer, onAnswer, onNext, nextText, strength, data, userUid, hsk, isNew,
            onPlayAudio, showPinyin, usedAudioHint, onShowPinyin, usedLiteralHint, onShowLiteral } =  this.props;

        var audio = new Audio(`https://fanyi.baidu.com/gettts?lan=zh&text=${word}&spd=2`);
        return (
            <div className={classes.alignCenter}>
                <Card className={classes.card}>
                    <CardContent>
                        {!(showAnswer) &&
                            <React.Fragment>
                                <Grid container spacing={1}>
                                    <Grid className={classes.alignCenter} item xs={4}>
                                        <Tooltip title='Word'>
                                        <Typography color="primary" variant="h4">
                                            {word}
                                        </Typography>
                                        </Tooltip>
                                    </Grid>
                                    <Grid className={classes.alignCenter} item xs>
                                        {!(isNew) && <Tooltip title="Click buttons to the right and below to get hint">
                                        <Typography variant="button">
                                            {`Hints: ${showPinyin + usedAudioHint + usedLiteralHint}/3`}
                                        </Typography>
                                        </Tooltip>}
                                    </Grid>
                                    <Grid className={classes.alignCenter} item xs={4}>
                                        {(showPinyin || isNew) ? 
                                            <Tooltip title={`Pronunciation of ${word}`}>
                                            <Typography color="secondary" variant="h6">
                                                {wordPinyin.replace(/ /g, '')}
                                            </Typography></Tooltip> :
                                            <Tooltip title={`Show pronunciation of ${word}`}>
                                            <Button color="secondary" onClick={onShowPinyin}>Pinyin</Button>
                                            </Tooltip>}
                                    </Grid>
                                    <Grid className={classes.alignCenter} item xs>
                                        <Tooltip title={`Listen to pronunciation of ${word}`}>
                                        <IconButton aria-label="Audio" onClick={() => {
                                            audio.play();
                                            onPlayAudio();
                                        }}>
                                            <PlayArrowIcon className={classes.playIcon} />
                                        </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                <Grid className={classes.alignCenter} item xs={4}>
                                </Grid>
                                <Grid className={classes.alignCenter} item xs={8}>
                                    {(usedLiteralHint || isNew) ? 
                                        <Typography variant="h6">
                                        {word.split('').map(char =>
                                            `"${characters[char].meanings[0]}" `
                                        )}
                                        </Typography> :
                                        <Tooltip title={`Show literal meaning of individual characters of ${word}`}>
                                        <Button color="secondary" onClick={onShowLiteral}>Literal meaning</Button>
                                        </Tooltip>
                                    }
                                </Grid>
                                </Grid>
                                
                                {isNew ? <React.Fragment>
                                    <Typography className={classes.alignCenter} variant="body1">
                                        <br/>
                                        {`New word!`}
                                    </Typography>
                                    <div className={classes.alignCenter}><Button color="secondary" onClick={() => onAnswer(false)}>See meaning</Button></div>
                                </React.Fragment>     : 
                                <React.Fragment>
                                        <Typography className={classes.alignCenter} variant="body1">
                                            <br/>
                                            {`Remember the meaning of ${word}?`}
                                        </Typography>
                                    
                                    <div className={classes.alignCenter}>
                                        <Button color="secondary" onClick={() => onAnswer(true)}>I remember</Button>
                                        <Button color="primary" onClick={() => onAnswer(false)}>I forgot</Button>
                                    </div>
                                </React.Fragment>}
                            </React.Fragment>
                        }
                        {showAnswer && 
                            <React.Fragment>
                                <Grid container spacing={1}>
                                    <Grid className={classes.alignCenter} item xs={4}>
                                        <Typography color="primary" variant="h4">
                                        {word.split('').map(char =>
                                            <Tooltip title={`"${characters[char].meanings[0]}" (click to view or edit story details)`}>
                                            <span onClick={(event) => this.handleClick(event, char)}>
                                                {char}
                                            </span>
                                            </Tooltip>
                                        )}
                                        </Typography>
                                        <Popper 
                                            id={id} 
                                            open={open} 
                                            anchorEl={this.state.anchorEl}
                                        >
                                            <DetailCharacterContent
                                                data = {data}
                                                userUid = {userUid}
                                                char = {clickedChar}
                                                showAnswer = {true}
                                                usedPinyinHint = {false}
                                                onNext = {() => {}}
                                                nextText = 'save'
                                                onAnswer = {() => {}}
                                                getAudioHint = {() => {}}
                                                getPinyinHint = {() => {}}
                                            />
                                        </Popper>
                                    </Grid>
                                    <Grid className={classes.alignCenter} item xs={4}>
                                        <Tooltip title={`Pronunciation of ${word}`}>
                                        <Typography color="secondary" variant="h6">
                                            {wordPinyin.replace(/ /g, '')}
                                        </Typography>
                                        </Tooltip>
                                    </Grid>
                                    <Grid className={classes.alignCenter} item xs>
                                        <Tooltip title={`Listen to pronunciation of ${word} (${wordPinyin})`}>
                                        <IconButton aria-label="Audio" onClick={() => {
                                            audio.play();
                                        }}>
                                            <PlayArrowIcon className={classes.playIcon} />
                                        </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <div className={classes.alignCenter}>
                                    <div className={classes.textCenter}>
                                    <Tooltip title={`Meaning of ${word}`}>
                                    <Typography variant="h6">
                                        <br/>
                                        {translation}
                                    </Typography>
                                    </Tooltip>
                                    </div>
                                </div>
                                
                            </React.Fragment>
                        }
                    </CardContent>
                    <CardActions>
                        <Tooltip title={`${word} is an HSK level ${hsk} word`}>
                        <SortIcon />
                        </Tooltip>
                        <Tooltip title={`${word} is an HSK level ${hsk} word`}>
                        <Typography variant="button">
                            {hsk}
                        </Typography>
                        </Tooltip>
                        <Tooltip title={`Your memory of ${word} is ${strength.toFixed(0)}%. Words you know well will get asked less often in your practice session, words you tend to forget get asked more often`}> 
                        <FitnessCenterIcon />
                        </Tooltip>
                        <Tooltip title={`Your memory of ${word} is ${strength.toFixed(0)}%. Words you know well will get asked less often in your practice session, words you tend to forget get asked more often`}> 
                        <div className={classes.root}>
                            <LinearProgress variant="determinate" value={strength} />
                        </div>
                        </Tooltip>
                        { showAnswer ? 
                            (!!onNext) && <Button onClick={onNext}>{nextText}</Button> :
                            <Link to="/" style={{ color: 'inherit', textDecoration: 'inherit'}}> 
                                <Tooltip title="End practice session and return to homepage">
                                <Button>Stop</Button>
                                </Tooltip>
                            </Link>
                        }
                    </CardActions>
                </Card>
            </div>
        );
    }
}

WordCard.propTypes = {
    classes: PropTypes.object.isRequired,
  
};
  
WordCard.defaultProps = {
    nextText: 'Next'
};


 export default withStyles(styles)(WordCard);