import React, { Component } from "react";

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import SimpleCard from '../SimpleCard/SimpleCard';

import Popper from '@material-ui/core/Popper';

import DetailWordContent from '../../content/DetailWordContent/DetailWordContent';
import Typography from '@material-ui/core/Typography';


const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  control: {
    padding: theme.spacing(2),
  },
  typography: {
    padding: theme.spacing(2),
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

class SimpleGridWords extends Component {
  constructor(props) {
    super(props);
    this.state = {
        anchorEl: null,
        clickedWord: ''
    };
  }

  handleClick = (event, word) => {
    this.setState({
        anchorEl: this.state.anchorEl ? null : event.currentTarget,
        clickedWord: word        
      }
    );
  };

  getWords = () => {
    return Object.keys(this.props.data.words).filter(value => Object.keys(this.props.data.user.words).includes(value));
  }

  getNumber = () => {
    return !this.props.data.user.words ? 0 : Object.keys(this.props.data.user.words).length;
  }

  render() {
    // Styling
    const { classes } = this.props;

    const { clickedWord } = this.state;

    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popper' : undefined;

    // Properties
    const { data, userUid } = this.props;

    return (
        <React.Fragment>
        <Typography className={classes.alignCenter} variant="h6">{`You've learned ${this.getNumber()} compound words. Click to view details.`}</Typography>
        <Grid container className={classes.grid} spacing={2}>
            <Grid item xs={12}>
              <Grid container justify="center" spacing={1}>
                {!data.user.words ? '' : this.getWords().map((item, index) =>  (
                    <Grid key={index} item>
                        <SimpleCard
                          wide={true}
                          character = {data.user.words[item].word}
                          handleClick = {this.handleClick}
                        />
                    </Grid>
                ))}
                <Popper 
                  id={id} 
                  open={open} 
                  anchorEl={this.state.anchorEl}
                >
                    <DetailWordContent
                        data = {data}
                        userUid = {userUid}
                        showAnswer = {true}
                        usedPinyinHint = {false}
                        onNext = {false}
                        nextText = 'save'
                        onAnswer = {() => {}}
                        getAudioHint = {() => {}}
                        getPinyinHint = {() => {}}
                        word = { clickedWord }
                        usedAudioHint = {false}
                        usedLiteralHint = {false}
                        getLiteralHint = {() => {}}
                    />
                </Popper>
              </Grid>
            </Grid>
        </Grid>
        </React.Fragment>
    );
  }
}

SimpleGridWords.propTypes = {
    classes: PropTypes.object.isRequired,
  
    items: PropTypes.object,
  };

export default withStyles(styles)(SimpleGridWords);
