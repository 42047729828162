import React, { Component } from "react";

import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import ChildCareIcon from '@material-ui/icons/ChildCare';

const styles = (theme) => ({
});

class NavList extends Component {
  render () {
      // Styling
      const { classes } = this.props;

      return (
          <List>
            <ListItem button component={Link} to="/" key="Home">
              <ListItemIcon><HomeIcon /></ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button component={Link} to="/practice/" key="Practice">
              <ListItemIcon><FitnessCenterIcon /></ListItemIcon>
              <ListItemText primary="Study" />
            </ListItem>
            <ListItem button component={Link} to="/characters/" key="Characters">
              <ListItemIcon><ColorLensIcon /></ListItemIcon>
              <ListItemText primary="Characters Learned" />
            </ListItem>
            <ListItem button component={Link} to="/words/" key="Words">
              <ListItemIcon><ChildCareIcon /></ListItemIcon>
              <ListItemText primary="Compound Words Learned" />
            </ListItem>
            <ListItem button component={Link} to="/pinyins/" key="PinyinTable">
              <ListItemIcon><RecordVoiceOverIcon /></ListItemIcon>
              <ListItemText primary="Pronunciation Table" />
            </ListItem>
        </List>
      );
  }
}

NavList.propTypes = {
    classes: PropTypes.object.isRequired,
};
  
 export default withStyles(styles)(NavList);