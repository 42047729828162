import React, { Component } from "react";

import PropTypes from 'prop-types';

import { db } from '../../App/App'

import { withStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import { orange } from '@material-ui/core/colors';
import EditCard from '../EditCard/EditCard';
import Popper from '@material-ui/core/Popper';
import DetailCharacterContent from '../../content/DetailCharacterContent/DetailCharacterContent';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  orangeAvatar: {
    backgroundColor: orange[700],
    color: '#000',
  },
});

class ElementCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: props.elementObj.useful_name,
            anchorEl: null,
            clickedChar: ''
        };
    };

    handleChange = (evt) => {
        this.setState({
            value: evt.target.value,
        });
    }

    saveChange = (evt) => {
        var updatedElement = this.props.elementObj;
        updatedElement.useful_name = this.state.value;
        db.collection("users").doc(this.props.userUid).collection("elements").doc(this.props.elementName).set(updatedElement);
    }

    handleClick = (event, char) => {
        if (char in this.props.data.user.stories) {
            this.setState({
                anchorEl: this.state.anchorEl ? null : event.currentTarget,
                clickedChar: char        
                }
            );
        }
    };

    render() {
        // Styling
        const { classes } = this.props;

        // Properties
        const { elementObj, userUid, data } = this.props;

        const { value, clickedChar } = this.state;

        const open = Boolean(this.state.anchorEl);
        const id = open ? 'simple-popper' : undefined;

        return (
            <EditCard
                avatar={(elementObj.elementary_character == null) ? 
                    <Avatar imgProps={{'style':{'background-color':'#f57c00'}}} src={`/images/elements/${elementObj.picture.replace('jpg','png')}`} /> :
                    <React.Fragment>
                        <Avatar className={classes.orangeAvatar} onClick={(event) => this.handleClick(event, elementObj.elementary_character)}>{elementObj.elementary_character}</Avatar>
                        <Popper 
                            id={id} 
                            open={open} 
                            anchorEl={this.state.anchorEl}
                        >
                            <DetailCharacterContent
                                data = {data}
                                userUid = {userUid}
                                char = {clickedChar}
                                showAnswer = {true}
                                usedPinyinHint = {false}
                                onNext = {() => {}}
                                nextText = 'save'
                                onAnswer = {() => {}}
                                getAudioHint = {() => {}}
                                getPinyinHint = {() => {}}
                            />
                        </Popper>
                    </React.Fragment>
                }
                label="Object, action, phrase etc"
                helperText="Choose an object, action, phrase etc which reminds you of this element and can be easily used in stories"
                value={value}
                onChange={this.handleChange}
                onSave={this.saveChange}
            />
        );
    }
}

ElementCard.propTypes = {
    classes: PropTypes.object.isRequired,
  
    elementObj: PropTypes.object,
    userUid: PropTypes.string,
  };

export default withStyles(styles)(ElementCard);
