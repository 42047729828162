import React, { Component } from "react";

import PropTypes from 'prop-types';

import { db } from '../../App/App'

import { withStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import { blue } from '@material-ui/core/colors';
import EditCard from '../EditCard/EditCard';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';


const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  blueAvatar: {
    backgroundColor: blue[700],
    color: '#000',
  },
});

class StartCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: props.startObj.person
        };
    };

    handleChange = (evt) => {
        this.setState({
            value: evt.target.value,
        });
    }

    saveChange = (evt) => {
        var updatedStart = this.props.startObj;
        updatedStart.person = this.state.value;
        db.collection("users").doc(this.props.userUid).collection("starts").doc(this.props.startObj.start).set(updatedStart);
    }

    render() {
        // Styling
        const { classes } = this.props;

        // Properties
        const { startObj } = this.props;

        const { value } = this.state;

        return (
            <EditCard
                avatar={<Avatar className={classes.blueAvatar}>{startObj.start}</Avatar>}
                label="Person"
                helperText={`Choose a famous person or a person you know personally for characters starting with '${startObj.start}'`}
                bottomElement={<Link to="/pinyins/" style={{ color: 'inherit', textDecoration: 'inherit'}}> 
                    <Button>View pronunciation table</Button>
                 </Link>}
                value={value}
                onChange={this.handleChange}
                onSave={this.saveChange}
            />
        );
    }
}

StartCard.propTypes = {
    classes: PropTypes.object.isRequired,
  
    startObj: PropTypes.object,
    userUid: PropTypes.string,
  };

export default withStyles(styles)(StartCard);
