import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import SimpleGrid from '../../layout/SimpleGrid/SimpleGrid';


const styles = (theme) => ({
});

class CharactersContent extends Component {
    render () {
        // Styling
        const { classes } = this.props;

        // Properties
        const { data, user } = this.props;

        return (
            <SimpleGrid
                data = { data }
                userUid = { user.uid }
            />
        );
    }
}

CharactersContent.propTypes = {
    classes: PropTypes.object.isRequired,
  
    data: PropTypes.object,
    user: PropTypes.object,
  };
  
 export default withStyles(styles)(CharactersContent);