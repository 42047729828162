import React, { Component } from "react";
import ReactDOM from 'react-dom';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Chip from '@material-ui/core/Chip';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';


const styles = (theme) => ({
});

class StoryChip extends Component {
    render () {
        // Styling
        const { classes } = this.props;

        // Properties
        const { avatar, label, editable, color, size, insertInStory, inStory, storyRef, onEdit, tooltipText } = this.props;    

        return (
            <Tooltip title={tooltipText}>
            <Chip
                contentEditable={false}
                avatar={avatar}
                key={label}
                label={label}
                className={classes.chip}
                onDelete={editable ? onEdit: null}
                deleteIcon={editable ? <EditIcon />: null}
                onClick={(!!label && !!insertInStory) ? evt => {
                    evt.preventDefault();
                    
                    let cursorPosition = storyRef.current.selectionStart;
                    let textBeforeCursorPosition = storyRef.current.value.substring(0, cursorPosition)
                    let textAfterCursorPosition = storyRef.current.value.substring(cursorPosition, storyRef.current.value.length)
                    
                    insertInStory(evt, label, cursorPosition, textBeforeCursorPosition, textAfterCursorPosition);
                    storyRef.current.focus();
                    // does not work
                    // storyRef.current.selectionStart = storyRef.current.selectionEnd = cursorPosition + label.length;
                } : false}
                color={color}
                variant={inStory ? "default":"outlined"}
                size={size}
            />
            </Tooltip>
        );
    }
}

StoryChip.propTypes = {
    classes: PropTypes.object.isRequired,
  
    avatar: React.ReactElement,
    label: PropTypes.string,
    editable: PropTypes.bool,
    color: PropTypes.string,
    size: PropTypes.string,
    insertInStory: PropTypes.func
  };

StoryChip.defaultProps = {
    color: 'default',
    editable: true,
    size: 'medium',
    onEdit: () => {},
    insertInStory: false,
  };
  
 export default withStyles(styles)(StoryChip);