import React, { Component } from 'react';

import { db } from '../../App/App'

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import DetailCharacterContent from '../DetailCharacterContent/DetailCharacterContent';
import DetailWordContent from '../DetailWordContent/DetailWordContent';
import DetailSentenceContent from '../DetailSentenceContent/DetailSentenceContent';
import CircularProgress from '@material-ui/core/CircularProgress';


const styles = (theme) => ({
    alignCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

class PracticeContent extends Component {
    constructor(props) {
        super(props);
        this.state = this.initialState();
    }

    initialState() {
        return {
            'showAnswer': false,
            'usedAudioHint': false,
            'usedPinyinHint': false,
            'storyChanged': false,
            'usedLiteralHint': false,
            'type': null
        };
    }

    boxFreqMapping() {
        // more boxes since we want to be able to jump 3 boxes (answered without hint), 2 boxes (audio only), 1 box (pinyin), -1 box (dont know)
        // show this on strenght bar with animation for moving up/down
        return {
            0: 128,
            1: 64,
            2: 32,
            3: 16,
            4: 8,
            5: 4,
            6: 2,
            7: 1,
            8: 0
        };
    }

    minBox() {
        return Object.keys(this.boxFreqMapping())[0];
    }

    maxBox() {
        return Object.keys(this.boxFreqMapping())[Object.keys(this.boxFreqMapping()).length-1];
    }

    componentDidMount() {
        if ((!!this.props.data.user.stories) && (!!this.props.data.user.words) && (!!this.props.data.user.sentences)) {
            this.setState({...this.getCharOrWordOrSentence()});
        }
    }

    componentDidUpdate() {
        var receivedFirstData = (!('char' in this.state)) && ((!!this.props.data.user.stories) && (!!this.props.data.user.words) && (!!this.props.data.user.sentences));

        if (receivedFirstData) {
            this.setState({...this.getCharOrWordOrSentence()});
        }
    }

    frequencyForBox(boxNr) {
        return this.boxFreqMapping()[boxNr];
    }

    getFreqArray(userStories) {
        var freqArray = [];
        Object.keys(userStories).forEach((character) => {
            var freq = this.frequencyForBox(userStories[character].box);
            for (var i = 0; i < freq; i++) {
                freqArray.push(character);
            }
        });
        return freqArray;
    }

    maximumFractionInBox0 () {
        return 0.1;
    }

    getExistingCharacterOrWord() {
        var freqArray = this.getFreqArray({...this.props.data.user.stories,...this.props.data.user.words,...this.props.data.user.sentences});
        var chosenCharacterOrWordOrSentence = freqArray[Math.floor(Math.random() * freqArray.length)];
        return chosenCharacterOrWordOrSentence;
    }

    getCharOrWordOrSentence() {
        var characterOrWordOrSentence = this.getNextCharacterOrWordOrSentence();
        if (isNaN(characterOrWordOrSentence)) {
            if (characterOrWordOrSentence.length === 1) {
                return this.getCharAndStory(characterOrWordOrSentence);
            } else if (characterOrWordOrSentence.length > 1)  {
                return this.getWord(characterOrWordOrSentence);
            }
        } else {
            return this.getSentence(characterOrWordOrSentence);
        }
    }

    getCharAndStory(character) {
        var storyObj = {};
        var isNew = false;
        if (character in this.props.data.user.stories) {
            storyObj = this.props.data.user.stories[character];
        } else {
            storyObj = this.props.data.stories[character];
            if(!storyObj) {
                storyObj = {box: 0, character: character, review_scores:[], story: ''};
                this.props.data.stories[character] = storyObj;
                db.collection("stories").doc(character).set(storyObj);
            }
            isNew = true;
            db.collection("users").doc(this.props.user.uid).collection("stories").doc(character).set(storyObj);
        }
        return {
            char: character,
            story: storyObj,
            isNew: isNew,
            type: "character"
        }
    }

    getWord(word) {
        var storyObj = {};
        var isNew = false;

        if (word in this.props.data.user.words) {
            storyObj = this.props.data.user.words[word];
        } else {
            storyObj = this.props.data.words[word];
            isNew = true;
            db.collection("users").doc(this.props.user.uid).collection("words").doc(word).set(storyObj);
        }

        return {
            char: word,
            story: storyObj,
            isNew: isNew,
            type: "word"
        }
    }

    getSentence(sentenceIdx) {
        var storyObj = {};
        var isNew = false;

        if (("sentences" in this.props.data.user) && (sentenceIdx in this.props.data.user.sentences)) {
            storyObj = this.props.data.user.sentences[sentenceIdx];
        } else {
            storyObj = this.props.data.sentences[sentenceIdx];
            isNew = true;

            db.collection("users").doc(this.props.user.uid).collection("sentences").doc(sentenceIdx.toString()).set(storyObj);
        }

        return {
            char: sentenceIdx.toString(),
            story: storyObj,
            isNew: isNew,
            type: "sentence"
        }
    }

    getNewCharacter() {
        var allCharacters = Object.keys(this.props.data.characters);
        // filter characters that are not used in hsk 
        allCharacters = allCharacters.filter(char => !(this.props.data.characters[char].unused_in_hsk));

        var userCharacters = [];
        if (this.props.data.user.stories) {
            userCharacters = Object.keys(this.props.data.user.stories);
        }
        var unknownCharacters = allCharacters.filter(x => !userCharacters.includes(x));
        if (unknownCharacters.length) {
            var nextCharacter = unknownCharacters[0];
            return nextCharacter;
        }
        return false;
    }

    existingNeedPractice() {
        var userStories = this.props.data.user.stories;
        var userWords = this.props.data.user.words;
        var userSentences = this.props.data.user.sentences;

        var nrInBox0 = 0;
        var total = 0;
        if (Object.entries(userStories).length > 0) {
            Object.keys(userStories).forEach((character) =>  {
                if (userStories[character].box === 0) {
                    nrInBox0++;
                }
            });
            total += Object.entries(userStories).length;
        }
        if (Object.entries(userWords).length > 0) {
            Object.keys(userWords).forEach((word) =>  {
                if (userWords[word].box === 0) {
                    nrInBox0++;
                }
            });
            total += Object.entries(userWords).length;
        }
        if (userSentences && (Object.entries(userSentences).length > 0)) {
            Object.keys(userSentences).forEach((word) =>  {
                if (userSentences[word].box === 0) {
                    nrInBox0++;
                }
            });
            total += Object.entries(userSentences).length;
        }
        var fractionInBox0 = nrInBox0 / total;
        return (fractionInBox0 > this.maximumFractionInBox0());
    }

    getNextCharacterOrWordOrSentence() {
        if (this.existingNeedPractice()) {
            return this.getExistingCharacterOrWord();
        } else {
            var newWord = this.getNewWord();
            if (newWord) {
                return newWord;
            } else {
                var newSentence = false;
                if (Math.random() < 0.35) { 
                    newSentence = this.getNewSentence();
                }
                if (newSentence !== false) {
                    return newSentence;
                } else {
                    var char = this.getNewCharacter();
                    if (char) {
                        return char;
                    } else {
                        return this.getExistingCharacterOrWord();
                    };
                }
            }
        }
    }

    getNewWord() {
        var userCharacterArr = Object.keys(this.props.data.user.stories);
        var wordArr = Object.keys(this.props.data.words);

        for (var i = 0; i < wordArr.length; i++) {
            var word = wordArr[i];
            var knownChars = word.split('').filter(char => userCharacterArr.includes(char));
            if (knownChars.length === word.length) {
                if (!('words' in this.props.data.user) || !(word in this.props.data.user.words)) {
                    return word;
                }
            }
        }
        return false;
    }

    getNewSentence() {
        var userCharacterArr = Object.keys(this.props.data.user.stories);
        var sentenceObj = this.props.data.sentences;
       
        for (const [i, sentence] of Object.entries(sentenceObj)) {
            var sentenceWords = sentence.hanzi.split("");
            var nrMissing = 0;
            
            for (var j = 0; j < sentenceWords.length; j++) {
                var word = sentenceWords[j];
                if ((!([' ', ',', '.', '，', '。', '', '＂', '“', '?', '？', '!', '！', ':', '(', ')',  'A', 'B', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 
                'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
                'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'].includes(word))) 
                && (!(userCharacterArr.includes(word)))) {
                    nrMissing++;
                    if (nrMissing > 0) {
                        break;
                    }
                }
                if (j === sentenceWords.length - 1) {
                    if (!('sentences' in this.props.data.user) || !(i in this.props.data.user.sentences)) {
                        return i;
                    }
                }
            }
        }
        return false;
    }

    calculateBox = (score) => {
        var box = this.state.story.box || 0;
        box = Math.max(this.minBox(), Math.min(this.maxBox(), box + score));
        return box;
    }

    score = (knows) => {
        var score = 0;
        if (knows) {
            score += 3;
            if (this.state.usedPinyinHint) {
                score -= 2;
            } else if (this.state.usedAudioHint) {
                score -= 1;
            }
        } else {
            score = -1;
        }
        var newReviewScores = this.state.story.review_scores.concat(score);
        var newBox = this.calculateBox(score);

        db.collection("users").doc(this.props.user.uid).collection("stories").doc(this.state.char).update({
            'review_scores': newReviewScores,
            'box': newBox
        });
    }

    processAnswer = (knows) => {
        this.score(knows);
        this.setState({showAnswer: true});
    }

    scoreWord = (knows) => {
        var score = 0;
        if (knows) {
            score += 3;
            if (this.state.usedLiteralHint) {
                score -= 2;
            } else if (this.state.usedPinyinHint) {
                score -= 1;
            } else if (this.state.usedAudioHint) {
                score -= 1;
            }
        } else {
            score = -1;
        }
        var newReviewScores = this.state.story.review_scores.concat(score);
        var newBox = this.calculateBox(score);

        db.collection("users").doc(this.props.user.uid).collection("words").doc(this.state.char).update({
            'review_scores': newReviewScores,
            'box': newBox
        });        
    }

    processAnswerWord = (knows) => {
        this.scoreWord(knows);
        this.setState({showAnswer: true});
    }

    scoreSentence = (knows) => {
        var score = 0;
        if (knows) {
            score += 3;
            if (this.state.usedPinyinHint) {
                score -= 1;
            } 
            if (this.state.usedAudioHint) {
                score -= 1;
            }
        } else {
            score = -1;
        }
        var newReviewScores = this.state.story.review_scores.concat(score);
        var newBox = this.calculateBox(score);

        db.collection("users").doc(this.props.user.uid).collection("sentences").doc(this.state.char).update({
            'review_scores': newReviewScores,
            'box': newBox
        });        
    }

    processAnswerSentence = (knows) => {
        this.scoreSentence(knows);
        this.setState({showAnswer: true});
    }

    getAudioHint = () => {
        this.setState({usedAudioHint: true});
    }

    getPinyinHint = () => {
        this.setState({usedPinyinHint: true});
    }

    getLiteralHint = () => {
        this.setState({usedLiteralHint: true});
    }

    getNext = () => {
        this.setState({...this.initialState(), ...this.getCharOrWordOrSentence()});
    }

    render () {
        // Styling
        const { classes } = this.props;

        const { char, isNew, showAnswer, usedPinyinHint, usedAudioHint, type, usedLiteralHint } = this.state;
        const { data, user } = this.props;

        return (
            <React.Fragment>
            {(type === "character") && <DetailCharacterContent
                data = {data}
                userUid = {user.uid}
                char = {char}
                isNew = {isNew}
                showAnswer = {showAnswer}
                usedPinyinHint = {usedPinyinHint}
                usedAudioHint = {usedAudioHint}
                onNext = {this.getNext}
                onAnswer = { this.processAnswer }
                getAudioHint = { this.getAudioHint }
                getPinyinHint = { this.getPinyinHint }
            />}
            {(type === "word") && <DetailWordContent
                word = { char }
                data = {data}
                userUid = {user.uid}
                isNew = {isNew}
                showAnswer = {showAnswer}
                onNext = {this.getNext}
                onAnswer = {this.processAnswerWord}
                usedPinyinHint = {usedPinyinHint}
                usedAudioHint = {usedAudioHint}
                usedLiteralHint = {usedLiteralHint}
                getAudioHint = { this.getAudioHint }
                getPinyinHint = { this.getPinyinHint }
                getLiteralHint = { this.getLiteralHint }
            />}
            {(type === "sentence") && <DetailSentenceContent
                sentence = { char }
                data = {data}
                userUid = {user.uid}
                isNew = {isNew}
                showAnswer = {showAnswer}
                onNext = {this.getNext}
                onAnswer = {this.processAnswerSentence}
                usedPinyinHint = {usedPinyinHint}
                usedAudioHint = {usedAudioHint}
                getAudioHint = { this.getAudioHint }
                getPinyinHint = { this.getPinyinHint }
            />}
            {(!type) && <div className={classes.alignCenter}><CircularProgress /></div>}
            </React.Fragment>
        );
    }
}

PracticeContent.propTypes = {
    classes: PropTypes.object.isRequired,
  
    data: PropTypes.object,
    user: PropTypes.object
  };
  
 export default withStyles(styles)(PracticeContent);