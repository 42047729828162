import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';

import CodeIcon from '@material-ui/icons/Code';
import HomeIcon from '@material-ui/icons/Home';

import GitHubCircleIcon from 'mdi-material-ui/GithubCircle';

import EmptyState from '../../layout/EmptyState/EmptyState';

const styles = (theme) => ({
  emptyStateIcon: {
    fontSize: theme.spacing(12)
  },

  button: {
    marginTop: theme.spacing(1)
  },

  buttonIcon: {
    marginRight: theme.spacing(1)
  }
});

class HomeContent extends Component {
  render() {
    // Styling
    const { classes } = this.props;

    // Properties
    const { isSignedIn, title, onSignUpClick } = this.props;

    return (
      <EmptyState
        icon={<HomeIcon className={classes.emptyStateIcon} color="action" />}
        title={ title }
        description={<React.Fragment>
          <p>Learn 1000 most frequent characters.</p>
          <p>Enables you to reach HSK4 level.</p>
          <p>Screenshot of cards with arrows with explanations</p>
          <p>First 30 days of studying are free! After that, we'll ask you to make a contribution of your choice.</p>
          <p>Spaced repetition (diagram to explain)</p>
          <p>Personalized: choose mnemonics (replace word?) that work for you</p>
          <p>Method requires more upfront investment but will enable you to learn faster and more effective afterwards.
          Only for those who are serious about learning Chinese.</p>
          <p>Heisig (picture of book?)</p>
          <p>Mnemonics</p>
          <p>Method of Loci (picture of Cato?)</p>
          <p>Quotes: I tried many methods to learn Chinese characters, but this was the only one that made them stick</p>
          </React.Fragment>}
        button={

          isSignedIn ? <Fab className={classes.button} color="secondary" component={Link} to="/practice/" variant="extended">
            Start studying
          </Fab> : <Fab className={classes.button} color="secondary" onClick={onSignUpClick} variant="extended">
            Sign up to start studying
          </Fab>          
        }
      />
    );
  }
}

HomeContent.propTypes = {
  classes: PropTypes.object.isRequired,

  isSignedIn: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired
};

export default withStyles(styles)(HomeContent);