import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import Popper from '@material-ui/core/Popper';
import StartCard from '../../layout/StartCard/StartCard';
import EndCard from '../../layout/EndCard/EndCard';
import { getForUserWithFallback } from '../../App/utils';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';


const styles = (theme) => ({
    root: {
        width: '100%',
    },
    tableWrapper: {
        overflow: 'auto',
        marginRight: 5,
        marginLeft: 5
    },
});

class PinyinsContent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            anchorElStart: null,
            anchorElEnd: null,
            clickedStart: {},
            clickedEnd: {},
        };
    };

    handleStartEdit = (event, startObj) => {
        this.setState({
            anchorElStart: this.state.anchorElStart ? null : event.currentTarget,
            clickedStart: startObj,
          }
        );
    };

    handleEndEdit = (event, endObj) => {
        this.setState({
            anchorElEnd: this.state.anchorElEnd ? null : event.currentTarget,
            clickedEnd: endObj,
          }
        );
    };

    handleClickAway = () => {
        this.setState({
            anchorElEnd: null,
            anchorElStart: null,
        });
    };

    getStarts = () => {
        return Object.keys(this.props.data.starts);
    }

    getEnds = () => {
        return Object.keys(this.props.data.ends);
    }

    getPinyinForCell = (start, end) => {
        var pinyins_cell = Object.values(this.props.data.pinyins).filter(function (pinyin) {
            return pinyin.start === start && pinyin.end === end;
        });
        if(typeof pinyins_cell[0] !== 'undefined') {
            return pinyins_cell[0].pinyin_simple.replace(/[0-9]/g, '');
        }
        return '';
    }

    render () {
        // Styling
        const { classes } = this.props;

        const { data, user } = this.props;

        const openStart = Boolean(this.state.anchorElStart);
        const idStart = openStart ? 'popper-start' : undefined;

        const openEnd = Boolean(this.state.anchorElEnd);
        const idEnd = openEnd ? 'popper-end' : undefined;

        return (
            <Paper className={classes.paper}>
            <div className={classes.tableWrapper}>
            <Table className={classes.table} size="small">
            <TableBody>
                <TableRow>
                    <TableCell padding='none' align='left'>end→<br/>↓start</TableCell>
                    {this.getEnds().map(end => {
                        return (
                            <TableCell align='right' padding='none'>

                                <IconButton size='small' onClick={(evt) => this.handleEndEdit(evt, getForUserWithFallback(end, 'ends', data))}>
                                    <EditIcon fontSize='small' />
                                </IconButton>
                                <Typography variant="overline">{end}</Typography>
                            </TableCell>
                        )
                    })}
                </TableRow>
                {this.getStarts().map(start => { 
                    return (
                        <TableRow key={start}>
                            <TableCell align='left' padding='none' component="th" scope="row" >
                                <IconButton size='small' onClick={(evt) => this.handleStartEdit(evt, getForUserWithFallback(start, 'starts', data))}>
                                    <EditIcon fontSize='small' />
                                </IconButton>

                                <Typography variant="overline">{start}</Typography>
                                
                            </TableCell>
                            {this.getEnds().map(end => {
                                return (
                                    <TableCell align='right' padding='none'>{this.getPinyinForCell(start,end)}</TableCell>
                                )
                            })}
                        </TableRow>
                    )
                })}
            </TableBody>
            </Table>
            </div>
            
            <Popper 
                id={idStart} 
                open={openStart} 
                anchorEl={this.state.anchorElStart}
            >
                <ClickAwayListener onClickAway={this.handleClickAway}>
                <StartCard
                    startObj = {this.state.clickedStart}
                    userUid = {user.uid}
                />
                </ClickAwayListener>
            </Popper>
            
            <Popper 
                id={idEnd} 
                open={openEnd} 
                anchorEl={this.state.anchorElEnd}
            >
                <ClickAwayListener onClickAway={this.handleClickAway}>
                <EndCard
                    endObj = {this.state.clickedEnd}
                    userUid = {user.uid}
                />
                </ClickAwayListener>
            </Popper>
            
            </Paper>
        );
    }
}

PinyinsContent.propTypes = {
    classes: PropTypes.object.isRequired,
  
    data: PropTypes.object,
  };
  
 export default withStyles(styles)(PinyinsContent);