import React, { Component } from "react";

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import StoryChip from '../StoryChip/StoryChip';
import ElementCard from '../ElementCard/ElementCard';
import StartCard from '../StartCard/StartCard';
import EndCard from '../EndCard/EndCard';
import ToneCard from '../ToneCard/ToneCard';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import IconButton from '@material-ui/core/IconButton';
import { MentionsInput, Mention } from 'react-mentions';
import defaultStyle from './defaultStyle'
import defaultMentionStyle from './defaultMentionStyle'
import meaningMentionStyle from './meaningMentionStyle'
import pinyinMentionStyle from './pinyinMentionStyle'
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import BackspaceIcon from '@material-ui/icons/Backspace';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';
import SortIcon from '@material-ui/icons/Sort';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    card: {
        width: 400,
        maxWidth: '99vw',
        minHeight: 400,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column'
    },
    alignCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});


class DetailCard extends Component {
    constructor(props) {
        super(props)
        this.storyInput = React.createRef();
        this.state = {
            anchorElElement: null,
            anchorElStart: null,
            anchorElEnd: null,
            anchorElTone: null,
            clickedElement: {},
            clickedStart: {},
            clickedEnd: {},
            clickedTone: {},
        };
    };

    pasteAsPlainText = event => {
        event.preventDefault()
        
        const text = event.clipboardData.getData('text/plain')
        document.execCommand('insertHTML', false, text)
    }

    disableNewlines = event => {
        const keyCode = event.keyCode || event.which
      
        if (keyCode === 13) {
          event.returnValue = false
          if (event.preventDefault) event.preventDefault()
        }
    }

    handleElementEdit = (event, elementName, elementObj) => {
        this.setState({
            anchorElElement: this.state.anchorElElement ? null : event.currentTarget,
            clickedElement: elementObj,
            clickedElementName: elementName    
          }
        );
    };

    handleStartEdit = (event, startObj) => {
        this.setState({
            anchorElStart: this.state.anchorElStart ? null : event.currentTarget,
            clickedStart: startObj,
          }
        );
    };

    handleEndEdit = (event, endObj) => {
        this.setState({
            anchorElEnd: this.state.anchorElEnd ? null : event.currentTarget,
            clickedEnd: endObj,
          }
        );
    };

    handleToneEdit = (event, toneObj) => {
        this.setState({
            anchorElTone: this.state.anchorElTone ? null : event.currentTarget,
            clickedTone: toneObj,
          }
        );
    };

    handleClickAway = () => {
        this.setState({
            anchorElEnd: null,
            anchorElStart: null,
            anchorElTone: null,
            anchorElElement: null
        });
    };

    render () {
        // Styling
        const { classes } = this.props;

        // Properties
        const { data, character, story, strength, isNew, pinyin, start, end, tone, meaning, elements, onAnswer, showAnswer, onPlayAudio, showPinyin, usedAudioHint, onShowPinyin, onNext, onChange,
            insertInStory, nextText, userUid, unusedInHsk } = this.props;

        const openElement = Boolean(this.state.anchorElElement);
        const idElement = openElement ? 'popper-element' : undefined;

        const openStart = Boolean(this.state.anchorElStart);
        const idStart = openStart ? 'popper-start' : undefined;

        const openEnd = Boolean(this.state.anchorElEnd);
        const idEnd = openEnd ? 'popper-end' : undefined;

        const openTone = Boolean(this.state.anchorElTone);
        const idTone = openTone ? 'popper-tone' : undefined;

        var audio = new Audio(`https://www.purpleculture.net/mp3/${pinyin.pinyin_simple}.mp3`);

        return (
            <div className={classes.alignCenter}>
                <Card className={classes.card}>
                    {unusedInHsk && <Typography variant="h6">
                                                Unused
                                            </Typography>}
                    {!showAnswer &&
                        <React.Fragment>
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid className={classes.alignCenter} item xs>
                                        <Tooltip title="Character">
                                        <Typography color="primary" variant="h4">{character.character}</Typography>
                                        </Tooltip>
                                    </Grid>
                                    <Grid className={classes.alignCenter} item xs>
                                        {isNew ?
                                            <StoryChip
                                                avatar={false}
                                                label={meaning}
                                                color="default"
                                                inStory={false}
                                                editable={false}
                                                tooltipText='Meaning'
                                            /> :
                                            <Tooltip title="Click buttons to the right to get hint">
                                            <Typography variant="button">
                                                {`Hints: ${showPinyin + usedAudioHint}/2`}
                                            </Typography>
                                            </Tooltip>
                                        }
                                    </Grid>
                                    <Grid className={classes.alignCenter} item xs={4}>
                                        {(showPinyin || isNew) ? 
                                            <Tooltip title="Pronunciation">
                                            <Typography color="secondary" variant="h6">
                                                {pinyin.pinyin}
                                            </Typography></Tooltip> :
                                            <Tooltip title={`Show pronunciation of ${character.character}`}>
                                            <Button color="secondary" onClick={onShowPinyin}>Pinyin</Button>
                                            </Tooltip>}
                                    </Grid>
                                    <Grid className={classes.alignCenter} item xs>
                                        <Tooltip title={`Listen to pronunciation of ${character.character}`}>
                                        <IconButton aria-label="Audio" onClick={() => {
                                            audio.play();
                                            onPlayAudio();
                                        }}>
                                            <PlayArrowIcon className={classes.playIcon} />
                                        </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                
                                
                               <Typography className={classes.alignCenter} variant="body1">
                               <div className={classes.alignCenter}>
                               <br/>
                                {isNew ? 'New character!':(showPinyin | usedAudioHint) ? `Remember the meaning of ${character.character}?`: `Remember the meaning and pronunciation of ${character.character}?`}
                                </div> 
                                </Typography>
                                { isNew ? <div className={classes.alignCenter}><Button color="secondary" onClick={() => onAnswer(false)}>Write story</Button></div> :
                                    <div className={classes.alignCenter}>
                                        <Button color="secondary" onClick={() => onAnswer(true)}>I remember</Button>
                                        <Button color="primary" onClick={() => onAnswer(false)}>I forgot</Button>
                                    </div>
                                }
                                
                            </CardContent>
                            <CardActions>
                                { character.hsk_level && 
                                    <React.Fragment>
                                    <Tooltip title={`${character.character} is an HSK level ${character.hsk_level} word`}>
                                    <SortIcon />
                                    </Tooltip>
                                    <Tooltip title={`${character.character} is an HSK level ${character.hsk_level} word`}>
                                    <Typography variant="button">
                                        {character.hsk_level}
                                    </Typography> 
                                    </Tooltip>
                                    </React.Fragment>
                                }

                                <Tooltip title={`Your memory of this character is ${strength.toFixed(0)}%. Characters you remember will get asked less often in your practice session, characters you forget get asked more often`}> 
                                <FitnessCenterIcon />
                                </Tooltip>
                                <Tooltip title={`Your memory of this character is ${strength.toFixed(0)}%. Characters you remember will get asked less often in your practice session, characters you forget get asked more often`}> 
                                <div className={classes.root}>
                                    <LinearProgress variant="determinate" value={strength} />
                                </div>
                                </Tooltip>
                                <Link to="/" style={{ color: 'inherit', textDecoration: 'inherit'}}> 
                                    <Tooltip title="End practice session and return to homepage">
                                    <Button>Stop</Button>
                                    </Tooltip>
                                </Link>
                            </CardActions>
                        </React.Fragment>
                    }

                    {showAnswer &&
                        <React.Fragment>
                            <CardContent>

                            <Grid container spacing={1}>
                                <Grid className={classes.alignCenter} item xs>
                                    <Tooltip title="Character">
                                    <Typography color="primary" variant="h4">{character.character}</Typography>
                                    </Tooltip>
                                </Grid>
                                <Grid className={classes.alignCenter} item xs>
                                    <StoryChip
                                        avatar={false}
                                        label={meaning}
                                        color="default"
                                        storyRef={this.storyInput}
                                        insertInStory={insertInStory}
                                        inStory={story.includes(meaning)}
                                        editable={false}
                                        tooltipText={`Meaning of ${character.character}`}
                                    />
                                </Grid>
                                <Grid className={classes.alignCenter} item xs={4}>
                                    <Tooltip title={`Pronunciation of ${character.character}`}>
                                    <Typography color="secondary" variant="h6">
                                        {pinyin.pinyin}
                                    </Typography>
                                    </Tooltip>
                                </Grid>
                                <Grid className={classes.alignCenter} item xs>
                                    <Tooltip title={`Listen to pronunciation of ${character.character} (${pinyin.pinyin})`}> 
                                    <IconButton aria-label="Audio" onClick={() => audio.play()}>
                                        <PlayArrowIcon className={classes.playIcon} />
                                    </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>


                            <Grid container spacing={3}>
                                <Grid item xs zeroMinWidth>
                                    {Object.keys(elements).map((element, idx) => (
                                        <React.Fragment>
                                        <StoryChip
                                            avatar={(elements[element].elementary_character == null) ? 
                                                <Avatar imgProps={{'style':{'background-color':'#f57c00'}}} src={`/images/elements/${elements[element].picture.replace('jpg','png')}`} /> :
                                                <Avatar>{elements[element].elementary_character}</Avatar>
                                            }
                                            label={elements[element].useful_name}
                                            size="small"
                                            storyRef={this.storyInput}
                                            insertInStory={insertInStory}
                                            inStory={story.includes(elements[element].useful_name)}
                                            color="primary"
                                            onEdit={(evt) => this.handleElementEdit(evt, element, elements[element])}
                                            tooltipText={`${character.character} element ${idx+1}`}
                                        /><br/>
                                        </React.Fragment>
                                    ))}

                                    <Popper 
                                        id={idElement} 
                                        open={openElement} 
                                        anchorEl={this.state.anchorElElement}
                                    >
                                        <ClickAwayListener onClickAway={this.handleClickAway}>
                                        <ElementCard
                                            elementObj = {this.state.clickedElement}
                                            elementName = {this.state.clickedElementName}
                                            userUid = {userUid}
                                            data = {data}
                                        /></ClickAwayListener>
                                    </Popper>

                                </Grid>
                                <Grid item xs zeroMinWidth>
                                <StoryChip
                                    avatar={
                                        <Avatar>{start.start}</Avatar>
                                    }
                                    label={start.person}
                                    size="small"
                                    storyRef={this.storyInput}
                                    insertInStory={insertInStory}
                                    inStory={story.includes(start.person)}
                                    color='secondary'
                                    onEdit={(evt) => this.handleStartEdit(evt, start)}
                                    tooltipText={`${pinyin.pinyin} starts with ${start.start}`}
                                />
                                <Popper 
                                    id={idStart} 
                                    open={openStart} 
                                    anchorEl={this.state.anchorElStart}
                                >
                                    <ClickAwayListener onClickAway={this.handleClickAway}>
                                    <StartCard
                                        startObj = {this.state.clickedStart}
                                        userUid = {userUid}
                                    /></ClickAwayListener>
                                </Popper>
                                <br/>
                                <StoryChip
                                    avatar={
                                        <Avatar>{end.end}</Avatar>
                                    }
                                    label={end.location}
                                    size="small"
                                    storyRef={this.storyInput}
                                    insertInStory={insertInStory}
                                    inStory={story.includes(end.location)}
                                    color='secondary'
                                    onEdit={(evt) => this.handleEndEdit(evt, end)}
                                    tooltipText={`${pinyin.pinyin} ends with ${end.end}`}
                                />
                                <Popper 
                                    id={idEnd} 
                                    open={openEnd} 
                                    anchorEl={this.state.anchorElEnd}
                                >
                                    <ClickAwayListener onClickAway={this.handleClickAway}>
                                    <EndCard
                                        endObj = {this.state.clickedEnd}
                                        userUid = {userUid}
                                    /></ClickAwayListener>
                                </Popper>
                                <br/>
                                <StoryChip
                                    avatar={
                                        <Avatar>{tone.tone}</Avatar>
                                    }
                                    label={tone.emotion}
                                    size="small"
                                    storyRef={this.storyInput}
                                    insertInStory={insertInStory}
                                    inStory={story.includes(tone.emotion)}
                                    color='secondary'
                                    onEdit={(evt) => this.handleToneEdit(evt, tone)}
                                    tooltipText={`${pinyin.pinyin} is ${tone.tone}th tone`}
                                />
                                <Popper 
                                    id={idTone} 
                                    open={openTone} 
                                    anchorEl={this.state.anchorElTone}
                                >
                                    <ClickAwayListener onClickAway={this.handleClickAway}>
                                    <ToneCard
                                        toneObj = {this.state.clickedTone}
                                        userUid = {userUid}
                                    />
                                    </ClickAwayListener>
                                </Popper><br/>
                                </Grid>
                            </Grid>
                            
                            <br/>
                            <Divider variant="middle" />
                            <Grid container spacing={0}>
                                <Grid item xs={11}>
                                <Tooltip title={`Write your story to remember ${character.character}. Include tags to remember its meaning (${meaning}), its elements and its pronunciation (${pinyin.pinyin})`}>
                                <Typography className={classes.alignCenter} variant="caption">Story: type '@' to insert tags</Typography>
                                </Tooltip>
                                </Grid>
                                <Grid item xs={1}>
                                <Tooltip title="Clear story">
                                <IconButton size='small' onClick={(evt) => onChange(evt, '')}>
                                    <BackspaceIcon fontSize='small' />
                                </IconButton>
                                </Tooltip>
                                </Grid>
                            </Grid>

                            <MentionsInput
                                value={story} 
                                onChange = {onChange}
                                placeholder={`Type your story for ${character.character}, use '@' to insert tags`}
                                style={defaultStyle}
                                inputRef={this.storyInput}
                                allowSuggestionsAboveCursor={true}
                                allowSpaceInQuery={true}
                            >
                                <Mention
                                    markup='@μ__display__[__id__]'
                                    trigger="@"
                                    data={[{
                                        id: meaning,
                                        display: meaning,
                                      }]}
                                    style={meaningMentionStyle}
                                />

                                <Mention
                                    markup='@π__display__[__id__]'
                                    trigger="@"
                                    data={[{
                                        id: start.start,
                                        display:start.person,
                                      },
                                      {
                                        id: end.end,
                                        display:end.location,
                                      },
                                      {
                                        id: tone.tone,
                                        display:tone.emotion,
                                      }]}
                                    style={pinyinMentionStyle}
                                />
                                
                                <Mention
                                    markup='@ε__display__[__id__]'
                                    trigger="@"
                                    data={
                                        Object.keys(elements).map(element => (
                                            {id: element,
                                            display: elements[element].useful_name}
                                        ))
                                    }
                                    style={defaultMentionStyle}
                                />
                            </MentionsInput>

                            </CardContent>
                            <CardActions>
                                { character.hsk_level && 
                                    <React.Fragment>
                                    <Tooltip title={`${character.character} is an HSK level ${character.hsk_level} word`}>
                                    <SortIcon />
                                    </Tooltip>
                                    <Tooltip title={`${character.character} is an HSK level ${character.hsk_level} word`}>
                                    <Typography variant="button">
                                        {character.hsk_level}
                                    </Typography> 
                                    </Tooltip>
                                    </React.Fragment>
                                }
                                <Tooltip title={`Your memory of ${character.character} is ${strength.toFixed(0)}%. Characters you know well will get asked less often in your practice session, characters you tend to forget get asked more often`}> 
                                <FitnessCenterIcon />
                                </Tooltip>
                                <Tooltip title={`Your memory of ${character.character} is ${strength.toFixed(0)}%. Characters you know well will get asked less often in your practice session, characters you tend to forget get asked more often`}> 
                                <div className={classes.root}>
                                    <LinearProgress variant="determinate" value={strength} />
                                </div>
                                </Tooltip>
                                <Button onClick={onNext}>{nextText}</Button>
                            </CardActions>
                        </React.Fragment>
                    }
                </Card>
            </div>
        );
    }
}

DetailCard.propTypes = {
    classes: PropTypes.object.isRequired,
  
    character: PropTypes.object,
    meaning: PropTypes.string,
    story: PropTypes.string,
    strength: PropTypes.number,
    elements: PropTypes.object,
    pinyin: PropTypes.object,
    start: PropTypes.object,
    end: PropTypes.object,
    showAnswer: PropTypes.bool,
    onAnswer: PropTypes.func,
    onPlayAudio: PropTypes.func,
    showPinyin: PropTypes.bool,
    onShowPinyin: PropTypes.func,
    onNext: PropTypes.func,
    onChange: PropTypes.func,
    nextText: PropTypes.string,
    userUid: PropTypes.string,
  };
  
  DetailCard.defaultProps = {
    nextText: 'Next'
  };

 export default withStyles(styles)(DetailCard);