/* eslint-disable no-unused-vars */

import red from '@material-ui/core/colors/red';
import pink from '@material-ui/core/colors/pink';
import purple from '@material-ui/core/colors/purple';
import deepPurple from '@material-ui/core/colors/deepPurple';
import indigo from '@material-ui/core/colors/indigo';
import blue from '@material-ui/core/colors/blue';
import lightBlue from '@material-ui/core/colors/lightBlue';
import cyan from '@material-ui/core/colors/cyan';
import teal from '@material-ui/core/colors/teal';
import green from '@material-ui/core/colors/green';
import lightGreen from '@material-ui/core/colors/lightGreen';
import lime from '@material-ui/core/colors/lime';
import yellow from '@material-ui/core/colors/yellow';
import amber from '@material-ui/core/colors/amber';
import orange from '@material-ui/core/colors/orange';
import deepOrange from '@material-ui/core/colors/deepOrange';
import brown from '@material-ui/core/colors/brown';
import gray from '@material-ui/core/colors/grey';
import blueGray from '@material-ui/core/colors/blueGrey';

/* eslint-enable no-unused-vars */

const settings = {
  title: 'Loci Chinese',

  theme: {
    primaryColor: {
      name: 'orange',
      import: orange
    },
    secondaryColor: {
      name: 'lightBlue',
      import: lightBlue
    },
    type: 'light'
  },

  credentials: {
    firebase: {
      apiKey: "AIzaSyC1kBecUdP1WO2LRquWT6NO991aXs2-04I",
      authDomain: "effortful-chinese.firebaseapp.com",
      databaseURL: "https://effortful-chinese.firebaseio.com",
      projectId: "effortful-chinese",
      storageBucket: "effortful-chinese.appspot.com",
      messagingSenderId: "111125244361",
      appId: "1:111125244361:web:1647439e88816f86cf6018"
    }
  }
};

export default settings;