import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import HomeCard from '../HomeCard/HomeCard';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
        Loci Chinese
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

const styles = theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles(theme => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  
  const DialogActions = withStyles(theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

export default function Album({title, isSignedIn, onSignUpClick}) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Grid container spacing={0} justify="center">
                <Grid item>
                    <img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="Logo" style={{width: 300, height: "auto"}} />
                </Grid>
            </Grid>
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              {title}
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
              With Loci Chinese you will learn the 1000 most used Chinese characters and never forget them again!
              Our method combines spaced repetition for optimal memory retention with the ancient Greek method of Loci and Dr. Heisig's method for studying the characters.
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  { isSignedIn ? <Button component={Link} to="/practice/" variant="contained" color="primary">
                    Start studying
                  </Button>:
                  <Button variant="contained" color="primary" onClick={onSignUpClick}>
                    Sign up to start studying
                  </Button>}
                </Grid>
                <Grid item>
                  <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                    How does it work?
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            <Grid item key="HowWorks" xs={12} sm={6} md={4}>
            <HomeCard
                heading='How does it work?'
                text="You will write a story for each character (or personalize the story we provide) which contains its meaning, the elements used to write it and the way it's pronounced. Our app will enable you to write and practice these character stories in the most effective way."
                imgText="Practice"
                imgSource="/images/home/practice.png"
                onDetailClick={handleClickOpen}
            />
            </Grid>
            <Grid item key="LearnWhat" xs={12} sm={6} md={4}>
            <HomeCard
                heading='What will I learn?'
                text="You'll learn the writing, meaning and pronunciation of the 1000 most used characters in Chinese, which cover 90% of the vocabulary of HSK level 4."
                imgText="Characters"
                imgSource="/images/home/characters.png"
            />
            </Grid>
            <Grid item key="MethodOfLoci" xs={12} sm={6} md={4}>
            <HomeCard
                heading='What is the Method of Loci?'
                text="The method of Loci (also called Memory Palace) is a technique used for remembering large quantities of information. It was developed in ancient Greece but is still used by memory champions today in its modern form. The main idea is associating a vivid image with each item and placing the items along a certain route."
                imgText="Cicero"
                imgSource="/images/home/cicero.webp"
            />
            </Grid>
            <Grid item key="SpacedRepetition" xs={12} sm={6} md={4}>
            <HomeCard
                heading='What is spaced repetition?'
                text="Spaced repetition is an evidence based learning technique using flashcards. New and difficult cards will be repeated more often while cards that are remembered well are practiced less and less frequently. This maximizes the rate of learning. The method is based on Ebbinghaus' research on 'forgetting curves'."
                imgText="Leitner boxes"
                imgSource="/images/home/spacedrep.png"
            />
            </Grid>
            <Grid item key="HeisigMethod" xs={12} sm={6} md={4}>
            <HomeCard
                heading='What is the Heisig method?'
                text="The Heisig method was developed by Dr. James Heisig to remember Japanese and Chinese characters. It works by identifying the different elements of a character and incorporating them in a story. We have extended the Heisig method to also remember a character's pronuncation."
                imgText="Heisig"
                imgSource="/images/home/heisig.png"
            />
            </Grid>
            <Grid item key="TimeInvestment" xs={12} sm={6} md={4}>
            <HomeCard
                heading='Sounds like a lot of work..'
                text="Our study method does indeed require some upfront time investment. However we have designed our app to make this as easy as possible for you, and it will soon pay off in superior retention of the characters! Nevertheless, you will need dedication and consistence to make progress."
                imgText="Effort"
                imgSource="/images/home/effort.jpg"
            />
            </Grid>
            <Grid item key="Pricing" xs={12} sm={6} md={4}>
            <HomeCard
                heading='Is Loci Chinese free?'
                text="The first 30 days of study are free. If, after that, you've decided this is for you and you want to continue, we'll ask you for a monthly contribution of your choice. This will enable our developers to pay for their coffees!"
                imgText="Coffee"
                imgSource="/images/home/coffee.jpg"
            />
            </Grid>
            <Grid item key="Testimonials" xs={12} sm={6} md={4}>
            <HomeCard
                heading='Testimonials'
                text={<React.Fragment>"I've tried many methods to learn Chinese characters, but this was the only one that made them stick."<br/><i>Dominic</i><br/><br/>
                    "Loci Chinese is such a fun and original way to learn something I thought I'd never learn!"<br/><i>Joshua</i></React.Fragment>}
                imgText="Testimonials"
                imgSource="/images/home/quotes.png"
            />
            </Grid>
          </Grid>
        </Container>
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            How does it work?
            </DialogTitle>
            <DialogContent dividers>
            <Typography gutterBottom>
                You will write a story for each character (or personalize the story we provide) which contains its meaning, the elements used to write it and the way it's pronounced. Our app will enable you to write and practice these character stories in the most effective way.
            </Typography>
            <Grid container spacing={0} justify="center">
                <Grid item>
                    <img src={process.env.PUBLIC_URL + "/images/home/practice.png"} alt="Logo" style={{width: 400, height: "auto"}} />
                </Grid>
            </Grid>
            <Typography gutterBottom>
                You will learn the characters in an order which introduces the elements one by one. New characters will be introduced once you've practiced previous characters.
            </Typography>
            <Grid container spacing={0} justify="center">
                <Grid item>
                    <img src={process.env.PUBLIC_URL + "/images/home/elements.png"} alt="Logo" style={{width: 200, height: "auto"}} />
                </Grid>
            </Grid>
            <Typography gutterBottom>
                Elements appearing in a character are associated with a certain object or action that you can use in your story.
            </Typography>
            <Grid container spacing={0} justify="center">
                <Grid item>
                    <img src={process.env.PUBLIC_URL + "/images/home/pinyin.png"} alt="Logo" style={{width: 200, height: "auto"}} />
                </Grid>
            </Grid>
            <Typography gutterBottom>
                The pronuncation of each character consists of a start and an end (for example, 'guo' starts with 'gu' and ends with 'o'). The start is associated with a person appearing in your story. The end determines the location where your story takes place.
            </Typography>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} color="primary">
                Close
            </Button>
            </DialogActions>
        </Dialog>
      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Grid container spacing={0} justify="center">
            <Grid item>
                <img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="Logo" style={{width: 150, height: "auto"}} />
            </Grid>
        </Grid>
        <Typography variant="h6" align="center" gutterBottom>
          Our Purpose
        </Typography>
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          Loci Chinese aims to promote learning through interdisciplinary integration and consilience
        </Typography>
        <Copyright />
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
}