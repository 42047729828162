import React, { Component } from 'react';

import { db } from '../../App/App';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import DetailCard from '../../layout/DetailCard/DetailCard';

import { getForUserWithFallback } from '../../App/utils';


const styles = (theme) => ({
});

class DetailCharacterContent extends Component {
    constructor(props) {
        super(props);
        this.state = this.initialState();
    }

    initialState() {
        return {
            'storyChanged': false
        };
    }

    dataReady() {
        return ((!!this.props.data.user.stories) && ('一' in this.props.data.characters) && ('de' in this.props.data.pinyins) && ('-' in this.props.data.starts) && ('-' in this.props.data.ends) && (1 in this.props.data.tones) && ('Youngster' in this.props.data.elements));
    }

    componentDidMount() {
        if (this.dataReady()) {
            this.setState(this.getCharacterObjs(this.props.char));
        }
    }

    componentDidUpdate() {
        var receivedFirstData = ((!('character' in this.state)) && (this.dataReady()));
        var receivedNewData = (!receivedFirstData) && ('character' in this.state) && (this.state.character.character !== this.props.char)

        if ((receivedFirstData) || (receivedNewData)) {
            this.setState(this.getCharacterObjs(this.props.char));
        }
    }

    getCharacterObjs(character) {
        var storyObj = {};
        if (character in this.props.data.user.stories) {
            storyObj = this.props.data.user.stories[character];
        } else {
            storyObj = this.props.data.stories[character];
            db.collection("users").doc(this.props.userUid).collection("stories").doc(character).set(storyObj);
        }
        var characterObj = this.props.data.characters[character];
        var elementsObj = this.getElements(characterObj);
        var pinyinObj = this.props.data.pinyins[characterObj.pinyins[0]];
        var startObj = getForUserWithFallback(pinyinObj.start, 'starts', this.props.data);
        var endObj = getForUserWithFallback(pinyinObj.end, 'ends', this.props.data);
        var toneObj = getForUserWithFallback(pinyinObj.tone, 'tones', this.props.data);
        return {
            character: characterObj,
            story: storyObj,
            elements: elementsObj,
            pinyin: pinyinObj,
            start: startObj,
            end: endObj,
            tone: toneObj
        };
    }

    getElements(characterObj) {
        var elementsObj = {}
        characterObj.elements.forEach(element => {
            elementsObj[element] = getForUserWithFallback(element, 'elements', this.props.data);
        });
        return elementsObj;
    }

    addFormatFunc = () => {
        if (!String.prototype.format) {
            String.prototype.format = function(dict) {
              return this.replace(/{(\w+)}/g, function(match, key) {
                return typeof dict[key] !== 'undefined'
                  ? dict[key]
                  : match
                ;
              });
            };
        }
    }

    formatEl = (name, meaning, prefix) => {
        return `@${prefix}${name}[${meaning}]`
    }

    getFormatStr = (formattingFunc) => {
        console.log(this.state.character);
        var formatArgs = {
            person: formattingFunc(this.state.start.person, this.state.start.start, 'π'),
            universe: formattingFunc(this.state.end.location, this.state.end.end, 'π'),
            location: formattingFunc(this.state.tone.emotion, this.state.tone.tone, 'π'),
            meaning: formattingFunc(this.state.character.meanings[0], this.state.character.meanings[0], 'μ')
        }
        Object.keys(this.state.elements).forEach((element, index) => {
            formatArgs[`element${index}`] = formattingFunc(this.state.elements[element].useful_name, element, 'ε')
        })
        return formatArgs;
    }

    getFilledInStory = (story) => {
        this.addFormatFunc();
        return story.format(this.getFormatStr(this.formatEl));
    }

    extractContent = s => {
        var span = document.createElement('span');
        span.innerHTML = s;
        return span.textContent || span.innerText;
    }

    insertInStory = (event, str, cursor, textBefore, textAfter) => {
        var story = {...this.state.story};

        var formatDict = this.getFormatStr((name, meaning, type) => name);
        
        var insertEncoded = '';

        Object.keys(formatDict).forEach(key => {
            textBefore = textBefore.split(formatDict[key]).join(`{${key}}`);
            textAfter = textAfter.split(formatDict[key]).join(`{${key}}`);
            if (str === formatDict[key]) {
                insertEncoded = `{${key}}`;
            }
        })

        if (textBefore + textAfter === story.story) {
            var newStory = textBefore + insertEncoded + textAfter;
            story.story = newStory;
            this.setState({story: story, storyChanged: true}); 
        }
    }

    storyChange = (event, newStory) => {
        var story = {...this.state.story};

        var formatStr = this.getFormatStr(this.formatEl);
        Object.keys(formatStr).forEach(key => {
            var formatText = this.extractContent(formatStr[key]);
            newStory = newStory.split(formatText).join(`{${key}}`);
        })

        story.story = newStory;
        this.setState({story: story, storyChanged: true}); 
    }

    save = () => {
        if (this.state.storyChanged) {
            db.collection("users").doc(this.props.userUid).collection("stories").doc(this.state.character.character).update({
                'story': this.state.story.story
            });
        }
    }

    render () {
        // Styling
        const { classes } = this.props;

        const { showAnswer, usedPinyinHint, usedAudioHint, onNext, onAnswer, nextText, getAudioHint, getPinyinHint, userUid, isNew, data } = this.props;
        const { character, elements, story, pinyin, start, end, tone } = this.state;

        return (
            !character ? '' : <DetailCard
                data = {data}
                character = { character }
                pinyin = { pinyin }
                start = { start }
                end = { end }
                tone = { tone }
                meaning = { character.meanings[0] }
                story = { this.getFilledInStory(story.story) }
                isNew = { isNew }
                strength = {(!character || !this.props.data.user.stories[character.character]) ? 0 : (100 * this.props.data.user.stories[character.character].box + 1) / 8}
                elements = { elements }
                showAnswer = { showAnswer }
                onAnswer = { onAnswer }
                onPlayAudio = { getAudioHint }
                showPinyin = { usedPinyinHint }
                usedAudioHint = { usedAudioHint }
                onShowPinyin = { getPinyinHint }
                onNext = { () => {
                    this.save();
                    onNext();
                }}
                nextText = { nextText }
                onChange = { this.storyChange }
                insertInStory = { this.insertInStory }
                userUid = { userUid }
                unusedInHsk = {character.unused_in_hsk}
            />
        );
    }
}

DetailCharacterContent.propTypes = {
    classes: PropTypes.object.isRequired,
  
    data: PropTypes.object,
    userUid: PropTypes.string,
    char: PropTypes.string,
    showAnswer: PropTypes.bool,
    usedPinyinHint: PropTypes.bool,
    onNext: PropTypes.func,
    onAnswer: PropTypes.func,
  };
  
 export default withStyles(styles)(DetailCharacterContent);