import React, { Component } from "react";

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';


const styles = (theme) => ({
  cardContent: {
    flexGrow: 1,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
});

class HomeCard extends Component {

    render() {
        // Styling
        const { classes } = this.props;

        // Properties
        const {heading, text, imgSource, imgText, onDetailClick} = this.props;


        return (
            <Card className={classes.card}>
            <CardMedia
              className={classes.cardMedia}
              image={imgSource}
              title={imgText}
            />
            <CardContent className={classes.cardContent}>
              <Typography gutterBottom variant="h5" component="h2">
                {heading}
              </Typography>
              <Typography>
                {text}
              </Typography>
            </CardContent>
            <CardActions>
              { !onDetailClick ? "":
              <Button size="small" color="primary" onClick={onDetailClick}>
                Details
              </Button>
              }
            </CardActions>
          </Card>
        );
    }
}

HomeCard.propTypes = {
    classes: PropTypes.object.isRequired,
    
    heading: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    imgText: PropTypes.string.isRequired,
    onDetailClick: PropTypes.func
  };

export default withStyles(styles)(HomeCard);
