import React, { Component } from "react";

import PropTypes from 'prop-types';

import { db } from '../../App/App'

import { withStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import { orange } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';


const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    maxWidth: 400,
  },
  orangeAvatar: {
    backgroundColor: orange[700],
    color: '#000',
  },
});

class EditCard extends Component {

    render() {
        // Styling
        const { classes } = this.props;

        // Properties
        const { avatar, label, helperText, value, onChange, onSave, bottomElement } = this.props;

        return (
            <Card className={classes.card}>
                <CardContent>
                {avatar}
                <TextField
                    autoFocus={true}
                    label={label}
                    className={classes.textField}
                    helperText={helperText}
                    margin="normal"
                    onChange={onChange}
                    value={value}
                />
                
                </CardContent>
                <CardActions>
                    <Button onClick={onSave}>Save</Button>
                    { bottomElement }
                </CardActions>
            </Card>
        );
    }
}

EditCard.propTypes = {
    classes: PropTypes.object.isRequired,
  
    avatar: React.ReactElement,
    label: PropTypes.string, 
    helperText: PropTypes.string, 
    value: PropTypes.string, 
    onChange: PropTypes.func, 
    onSave: PropTypes.func,
    bottomElement: React.ReactElement,
};

EditCard.defaultProps = {
    bottomElement: <React.Fragment></React.Fragment>
};

export default withStyles(styles)(EditCard);
