import React, { Component } from "react";

import PropTypes from 'prop-types';

import { db } from '../../App/App'

import { withStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import { blue } from '@material-ui/core/colors';
import EditCard from '../EditCard/EditCard';


const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  blueAvatar: {
    backgroundColor: blue[700],
    color: '#000',
  },
});

class ToneCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: props.toneObj.emotion
        };
    };

    handleChange = (evt) => {
        this.setState({
            value: evt.target.value,
        });
    }

    saveChange = (evt) => {
        var updatedTone = this.props.toneObj;
        updatedTone.emotion = this.state.value;
        db.collection("users").doc(this.props.userUid).collection("tones").doc(`${this.props.toneObj.tone}`).set(updatedTone);
    }

    render() {
        // Styling
        const { classes } = this.props;

        // Properties
        const { toneObj } = this.props;

        const { value } = this.state;

        return (
            <EditCard
                avatar={<Avatar className={classes.blueAvatar}>{toneObj.tone}</Avatar>}
                label="Emotion or mood"
                helperText={`Choose an emotion or a mood to use in stories for characters with ${toneObj.tone}th tone`}
                value={value}
                onChange={this.handleChange}
                onSave={this.saveChange}
            />
        );
    }
}

ToneCard.propTypes = {
    classes: PropTypes.object.isRequired,
  
    toneObj: PropTypes.object,
    userUid: PropTypes.string,
  };

export default withStyles(styles)(ToneCard);
