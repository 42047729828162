import React, { Component } from "react";

import PropTypes from 'prop-types';

import { db } from '../../App/App'

import { withStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import { blue } from '@material-ui/core/colors';
import EditCard from '../EditCard/EditCard';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  blueAvatar: {
    backgroundColor: blue[700],
    color: '#000',
  },
});

class EndCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: props.endObj.location
        };
    };

    handleChange = (evt) => {
        this.setState({
            value: evt.target.value,
        });
    }

    saveChange = (evt) => {
        var updatedEnd = this.props.endObj;
        updatedEnd.location = this.state.value;
        db.collection("users").doc(this.props.userUid).collection("ends").doc(this.props.endObj.end).set(updatedEnd);
    }

    render() {
        // Styling
        const { classes } = this.props;

        // Properties
        const { endObj } = this.props;

        const { value } = this.state;

        return (
            <EditCard
                avatar={<Avatar className={classes.blueAvatar}>{endObj.end}</Avatar>}
                label="Location"
                helperText={`Choose a location you personally know well for characters ending with '${endObj.end}'. It works best if you choose all locations along a particular route, for example several places along your commute route.`}
                bottomElement={<Link to="/pinyins/" style={{ color: 'inherit', textDecoration: 'inherit'}}> 
                    <Button>View pronunciation table</Button>
                 </Link>}
                value={value}
                onChange={this.handleChange}
                onSave={this.saveChange}
            />
        );
    }
}

EndCard.propTypes = {
    classes: PropTypes.object.isRequired,
  
    endObj: PropTypes.object,
    userUid: PropTypes.string,
  };

export default withStyles(styles)(EndCard);
