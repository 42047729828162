import React, { Component } from "react";

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import SimpleCard from '../SimpleCard/SimpleCard';

import Popper from '@material-ui/core/Popper';

import DetailCharacterContent from '../../content/DetailCharacterContent/DetailCharacterContent';
import Typography from '@material-ui/core/Typography';


const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  control: {
    padding: theme.spacing(2),
  },
  typography: {
    padding: theme.spacing(2),
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

class SimpleGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
        anchorEl: null,
        clickedChar: ''
    };
  }

  handleClick = (event, char) => {
    this.setState({
        anchorEl: this.state.anchorEl ? null : event.currentTarget,
        clickedChar: char        
      }
    );
  };

  getCharacters = () => {
    return Object.keys(this.props.data.characters).filter(value => Object.keys(this.props.data.user.stories).includes(value));
  }

  getNumber = () => {
    return !this.props.data.user.stories ? 0 : Object.keys(this.props.data.user.stories).length;
  }

  render() {
    // Styling
    const { classes } = this.props;

    const { clickedChar } = this.state;

    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popper' : undefined;

    // Properties
    const { data, userUid } = this.props;

    return (
        <React.Fragment>
        <Typography className={classes.alignCenter} variant="h6">{`You've learned ${this.getNumber()} characters. Click to view or edit story.`}</Typography>
        <Grid container className={classes.grid} spacing={2}>
            <Grid item xs={12}>
              <Grid container justify="center" spacing={1}>
                {!data.user.stories ? '' : this.getCharacters().map((item, index) =>  (
                    <Grid key={index} item>
                        <SimpleCard
                          character = {data.user.stories[item].character}
                          handleClick = {this.handleClick}
                        />
                    </Grid>
                ))}
                <Popper 
                  id={id} 
                  open={open} 
                  anchorEl={this.state.anchorEl}
                >
                      <DetailCharacterContent
                         data = {data}
                         userUid = {userUid}
                         char = {clickedChar}
                         showAnswer = {true}
                         usedPinyinHint = {false}
                         onNext = {() => {}}
                         nextText = 'save'
                         onAnswer = {() => {}}
                         getAudioHint = {() => {}}
                         getPinyinHint = {() => {}}
                      />
                </Popper>
              </Grid>
            </Grid>
        </Grid>
        </React.Fragment>
    );
  }
}

SimpleGrid.propTypes = {
    classes: PropTypes.object.isRequired,
  
    items: PropTypes.object,
  };

export default withStyles(styles)(SimpleGrid);
