import React, { Component } from 'react';

import { db } from '../../App/App';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import WordCard from '../../layout/WordCard/WordCard';

const styles = (theme) => ({
});

class DetailWordContent extends Component {
    getWordObjs(word) {
        return this.props.data.user.words[word];
    }

    getCharObjs(word) {
        var charObjs = {}
        word.split('').forEach(char => {
            charObjs[char] = this.props.data.characters[char];
        })
        return charObjs
    }

    render () {
        // Styling
        const { classes } = this.props;

        const { word, onNext, onAnswer, showAnswer, data, userUid, usedPinyinHint, usedAudioHint, usedLiteralHint, getAudioHint, getPinyinHint,
            getLiteralHint, isNew } = this.props;
        
        const wordObj = this.getWordObjs(word);
        const charObjs = this.getCharObjs(word);

        return (
            ((!!wordObj) && <WordCard 
                word = { wordObj.word }
                data = {data}
                hsk  = { wordObj.hsk_level }
                isNew = {isNew}
                userUid = {userUid}
                characters = { charObjs }
                wordPinyin = { wordObj.pinyin }
                translation = { wordObj.translation }
                onNext = { onNext }
                onAnswer = { onAnswer }
                showAnswer = { showAnswer }
                strength = {(!wordObj || !this.props.data.user.words[word]) ? 0 : (100 * this.props.data.user.words[word].box + 1) / 8}
                onPlayAudio = { getAudioHint }
                showPinyin = { usedPinyinHint }
                usedAudioHint = { usedAudioHint }
                onShowPinyin = { getPinyinHint }
                usedLiteralHint = { usedLiteralHint }
                onShowLiteral = { getLiteralHint }
            />)
        )
    };
}

DetailWordContent.propTypes = {
    classes: PropTypes.object.isRequired,
  
    data: PropTypes.object,
    userUid: PropTypes.string,
};
  
 export default withStyles(styles)(DetailWordContent);