import React, { Component } from "react";

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import Paper from '@material-ui/core/Paper';



const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 70,
    width: 70,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paperWide: {
    height: 70,
    width: 170,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  control: {
    padding: theme.spacing(2),
  },
  typography: {
    padding: theme.spacing(2),
  }
});

class SimpleCard extends Component {

    render() {
        // Styling
        const { classes } = this.props;

        const { wide } = this.props;

        // Properties
        const { character, handleClick } = this.props;


        return (
            <Paper 
                className={wide ? classes.paperWide : classes.paper}
                onClick = {evt => handleClick(evt, character)}
            >
                <Typography 
                    color="textSecondary" 
                    variant="h4">
                        {character}
                </Typography>
            </Paper>
        );
    }
}

SimpleCard.propTypes = {
    classes: PropTypes.object.isRequired,
  
    character: PropTypes.string,
  };

export default withStyles(styles)(SimpleCard);
