import React, { Component } from 'react';

import { db } from '../../App/App';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import SentenceCard from '../../layout/SentenceCard/SentenceCard';

const styles = (theme) => ({
});

class DetailSentenceContent extends Component {
    getSentenceObjs(sentence) {
        return this.props.data.user.sentences[sentence];
    }

    render () {
        // Styling
        const { classes } = this.props;

        const { sentence, onNext, onAnswer, showAnswer, data, userUid, usedPinyinHint, usedAudioHint, getAudioHint, getPinyinHint,
            isNew } = this.props;
        
        const sentenceObj = this.getSentenceObjs(sentence);

        return (
            ((!!sentenceObj) && <SentenceCard 
                sentence = { sentenceObj.hanzi }
                data = {data}
                isNew = {isNew}
                userUid = {userUid}
                sentencePinyin = { sentenceObj.pinyin }
                translation = { sentenceObj.translation }
                onNext = { onNext }
                onAnswer = { onAnswer }
                showAnswer = { showAnswer }
                strength = {(!sentenceObj || !this.props.data.user.sentences[sentence]) ? 0 : (100 * this.props.data.user.sentences[sentence].box + 1) / 8}
                onPlayAudio = { getAudioHint }
                showPinyin = { usedPinyinHint }
                usedAudioHint = { usedAudioHint }
                onShowPinyin = { getPinyinHint }
            />)
        )
    };
}

DetailSentenceContent.propTypes = {
    classes: PropTypes.object.isRequired,
  
    data: PropTypes.object,
    userUid: PropTypes.string,
};
  
 export default withStyles(styles)(DetailSentenceContent);